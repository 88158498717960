import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="trix"
export default class extends Controller {
  connect() {
    this.hideToolbar();
    addEventListener("trix-focus", this.showToolbar.bind(this));
    addEventListener("trix-blur", this.hideToolbar.bind(this));
  }

  disconnect() {
    removeEventListener("trix-focus", this.showToolbar.bind(this));
    removeEventListener("trix-blur", this.hideToolbar.bind(this));
  }

  hideToolbar() {
    if (this.toolbar) this.toolbar.hidden = true;
  }

  showToolbar() {
    if (this.toolbar) this.toolbar.hidden = false;
  }

  get toolbar() {
    return this.element.toolbarElement;
  }
}
