import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["reset", "editor"];
  static values = { delay: { type: Number, default: 500 } };

  connect() {
    this.submit = debounce(this.submit.bind(this), this.delayValue);

    if (this.hasEditorTarget) {
      this.editorTarget.addEventListener("trix-change", this.submit);
    }
  }

  disconnect() {
    if (this.hasEditorTarget) {
      this.editorTarget.removeEventListener("trix-change", this.submit);
    }
  }

  submit() {
    this.element.requestSubmit();
  }

  reset(e) {
    if (this.hasResetTarget && e.detail.success) {
      this.resetTarget.click();
    }
  }
}
